import { Component, computed, inject, OnInit, ViewChild } from '@angular/core';
import { App } from '@capacitor/app';
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  ModalController,
  NavController,
  Platform,
  ViewWillLeave,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { WelcomePage } from '@page/welcome/welcome.page';
import { AppDataService } from '@service/app-data.service';
import { PushService } from '@service/push.service';
import { addIcons } from 'ionicons';
import { bicycle, ellipsisVerticalCircle, people } from 'ionicons/icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ad6-page-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  standalone: true,
  imports: [
    IonBadge,
    IonIcon,
    IonLabel,
    IonTabs,
    IonTabButton,
    IonTabBar,
    TranslateModule,
  ],
})
export class TabsPage implements OnInit, ViewWillLeave {

  @ViewChild(IonTabs) localIonTabs: IonTabs;

  private appDataService = inject(AppDataService);
  private backSubscription: Subscription;
  private modalController = inject(ModalController);
  private navController = inject(NavController);
  private platform = inject(Platform);
  private pushService = inject(PushService);
  appSettings = this.appDataService.appSettings;
  eventSettings = this.appDataService.eventSettings;

  initialized = false;

  unreadNewsNotifications = this.appDataService.unreadNewsNotifications;
  unseenPassageCount = computed<number>(() => {
    const favs = this.appDataService.favorites();
    if (favs && favs.length > 0) {
      return favs.reduce((total, favorite) => total + (favorite.unreadPassages?.length ?? 0), 0);
    }

    return 0;
  });

  constructor() {
    addIcons({
      ellipsisVerticalCircle,
      bicycle,
      people,
    });
  }

  ngOnInit(): void {
    this.initialize();
  }

  ionViewDidEnter(): void {
    this.backSubscription = this.platform.backButton
      .subscribeWithPriority(-1, async () => {
        const activeTab = this.localIonTabs.getSelected();
        console.log(`BACK - On tab ${activeTab}`);
        if (this.localIonTabs.outlet) {
          if (this.localIonTabs.outlet.canGoBack()) {
            console.log(`On tab ${activeTab}, go back...`);
            await this.localIonTabs.outlet.pop();

            return;
          }

          if (activeTab === 'ad6') {
            // From the ad6 tab, always minimize the app on back button if there is no history
            console.log('On home page and cannot go back. Minimize app...');
            App.minimizeApp();
          } else {
            console.log('On other tab, navigate back...');
            this.navController.back();
          }
        }
      });

    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        await this.pushService.processStoredNotifications();
      }
    });
  }

  ionViewWillLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  async initialize(): Promise<void> {
    if (!this.initialized && !this.appSettings().skipWelcomePage) {
      const modal = await this.modalController.create({ component: WelcomePage });

      modal.present();

      const { data } = await modal.onWillDismiss();
      if (data.data === 'letsStartApp') {
        this.initialized = true;
      }
    } else {
      this.initialized = true;
    }
  }

  async onTabsChange(event: { tab: string }): Promise<void> {
    if (!this.pushService.skipNavigateToRootOnce && this.pushService.loading === false) {
      // When user switches tabs, force to go to root of tab
      await this.navController.navigateRoot(`tabs/${event.tab}`, { animated: true });
    } else {
      this.pushService.skipNavigateToRootOnce = false;
    }
  }
}
